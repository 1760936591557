<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="jobData === undefined">
      <h4 class="alert-heading">Erro ao carregar dados</h4>
      <div class="alert-body">
        Job não encontrado
        <b-link class="alert-link" :to="{ name: 'apps-jobs-list' }">
          Lista de jobs
        </b-link>
        for other jobs.
      </div>
    </b-alert>

    <template v-if="jobData">
      <div>
        <b-card title="Jobs">
          <b-row>
            <b-col md="6">
              <b-form-group label="Id" label-for="nameJobs">
                <b-form-input
                  v-model="jobData.ID"
                  label="name"
                  label-for="nameJobs"
                  readonly
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Descrição" label-for="nameJobs">
                <b-form-input
                  v-model="jobData.DESCRIPTION"
                  label="name"
                  label-for="nameJobs"
                  readonly
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="CRON" label-for="nameJobs">
                <b-form-input
                  v-model="jobData.CRON"
                  label="name"
                  label-for="nameJobs"
                  readonly
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Ativo" label-for="nameJobs">
                <b-form-input
                  v-model="jobData.ACTIVE"
                  label="name"
                  label-for="nameJobs"
                  readonly
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Duração" label-for="nameJobs">
                <b-form-input
                  v-model="jobData.DURATION"
                  label="name"
                  label-for="nameJobs"
                  readonly
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="5">
              <b-form-group label="Última execução" label-for="membersJobs">
                <b-form-input
                  id="created-date"
                  v-model="jobData.LAST_EXECUTION"
                  class="form-control"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Execução" label-for="nameJobs">
                <b-form-input
                  v-model="jobData.LAST_RESULT"
                  label="name"
                  label-for="nameJobs"
                  readonly
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Bloqueado" label-for="nameJobs">
                <b-form-input
                  v-model="jobData.BLOCK"
                  label="name"
                  label-for="nameJobs"
                  readonly
                >
                </b-form-input>
              </b-form-group>
            </b-col> </b-row
          ><br />

          <b-row>
            <b-col>
              <div class="text-right mr-2 mb-2 rButton">
                <b-button
                  v-if="restrictions('button_edit_job')"
                  :to="{
                    name: 'apps-jobs-edit',
                    params: { id: jobData.ID },
                  }"
                  variant="primary"
                >
                  {{ $t("message.buttons.edit") }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BCardText,
  BCardGroup,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
} from "bootstrap-vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import AccessControl from "@core/utils/access-control";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BCardText,
    BCardGroup,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormDatepicker,
    vSelect,
  },

  data() {
    return {
      userData: store.state.user.userData,
    };
  },

  setup() {
    const jobData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}job/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        jobData.value = response.data.body;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          jobData.value = undefined;
        }
      });

    return {
      jobData,
    };
  },

  methods: {
    restrictions(value) {
      return AccessControl(value);
    },
  },
};
</script>

<style></style>
